
/* SECTION - MENU 2
--------------------------------------------------------------------------------------------

// for menus we use a global scope, so we can address the body aswell

*/


$ac-menu-2-transparent: false; 
$ac-menu-2-transparent-on-home: false;
$ac-menu-2-z-index: 2222;
$ac-menu-2-background: $brand-white;
$ac-menu-2-gradient-before: false;
$ac-menu-2-gradient-before-color: $default-background-dark;
$ac-menu-2-logo-width: 220px; // height for medium up. 
$ac-menu-2-content-width: 1200px;
$ac-menu-2-divider-height: 30px;

$ac-menu-2-bar-top-enabled: true;
$ac-menu-2-bar-top-justify-content: space-between;
$ac-menu-2-bar-top-background: $brand-tertiary;

$ac-menu-2-main-background: $brand-white;
$ac-menu-2-main-content-secondary-menu-color: rgba($default-text-color, .5);

$ac-menu-2-bar-bottom-enabled: true;
$ac-menu-2-bar-bottom-justify-content: space-between;
$ac-menu-2-bar-bottom-background: $brand-background-primary;

//socials icon
$ac-menu-2-socials-icon-background: $default-icon-color-dark;
$ac-menu-2-socials-icon-hover-background: $brand-secondary;
$ac-menu-2-socials-icon-color: $default-icon-color-light;
$ac-menu-2-socials-icon-hover-color: $brand-primary;

// mobile
$ac-menu-2-mobile-fixed: true;  
$ac-menu-2-mobile-height: 70px;
$ac-menu-2-mobile-padding: 10px;
$ac-menu-2-mobile-svg-size: 25px;
$ac-menu-2-mobile-svg-color: $default-icon-color-dark;

$ac-menu-2-mobile-has-search: false;

// overlay
$ac-menu-2-overlay-container-background: $brand-primary;
$ac-menu-2-overlay-menu-primary-border: solid 1px $brand-lightest-gray;
$ac-menu-2-overlay-menu-primary-text-align: center; // left or center
$ac-menu-2-overlay-menu-secondary-border: none; // $default-border;
$ac-menu-2-overlay-menu-secondary-text-align: center; // left or center

// overlay background
$ac-menu-2-overlay-backdrop-background: rgba($default-background-dark, .8);

@import '__menu_2_globals';


/*--------------------------------------------------------------------------------------------*/

body {
    @include breakpoint(medium down) {
        padding-top: $ac-menu-2-mobile-height;   
    }
}

[data-m-type="menu-2"] {

    box-shadow: 0 0 10px rgba(black, .2);

    //
    //     SOCIALS
    //
    
    .ac_socials_text {
        display: none;
    }
    .ac_socials_link {
        flex-direction: row-reverse;
    }

}

.ac_menu-2_main{
    .ac_menu-2_main_content{
        padding-right: $default-padding / 4;
        a{
            color: rgba($brand-secondary, .9)!important;
            transition: color .3s;
            font-weight: 600;
            .svg-container{
                margin-right: .4rem;
            }
            svg {
                color: $brand-secondary;
                fill: $brand-secondary;
                transition: all .3s;
            }
            &:hover{
                color: $brand-secondary;
                svg {
                    color: $brand-primary;
                    fill: $brand-primary;
                }
            }
        }
    }
}


.ac_menu-2_main_container {
    padding: 10px;
    @include breakpoint(medium down) {
        display: flex;
        align-items: center;
    }
}


.ac_menu-2_logo_img {
    padding-left: $default-padding / 2;
    padding-right: $default-padding / 2;
    @include breakpoint(small only) {
        height: auto;
        width: 140px !important;
        max-width: 140px !important;
    }
}

.ac_menu-2_bar_top {
    box-shadow: inset 0px -3px 3px -3px rgba($brand-secondary, 0.2);
    padding: $default-padding / 4 0;
    a {
        font-size: 75%;
        font-weight: 700;
        opacity: .9;
        letter-spacing: 1px;
        transition: color .3s;
        text-transform: uppercase;
        color: $brand-primary-text;
    }
    li:hover { 
        > a{
            opacity: 1;
        }
    }
}
.ac_menu-2_main_content {
    a {
        color: rgba($brand-primary-text, .9)!important;
        text-transform: uppercase;
        font-size: 100%;
        transition: color .3s;
        letter-spacing: 1px;
        // color: $brand-gray;
        
        &:hover {
            color: $brand-primary-text!important;
        }
    }
}
.ac_menu-2_bar_bottom {
    box-shadow: inset 0px 3px 3px -3px rgba($brand-tertiary, 0.2);
    a {
        font-size: 100%;
        font-weight: 700;
        display: inline-block;
        transition: color .3s;
        opacity: .8;
        text-transform: uppercase;
        color: $brand-white;
        padding: 0 $default-padding / 2;
    }
    li:hover {
        > a{
            opacity: 1;
        } 
    }
    svg {
        fill: rgba($brand-white, .7);
    }
}

ul {
    .current-page-item,
    .current-menu-ancestor,
    .current-menu-item{
         a{
        opacity: 1;
        }
    }
}

.ac_menu-2_bar_bottom_container {
    width: 100%;
    // justify-content: center;
    padding: $default-padding / 2;
}

.ac_menu-2_bar_bottom_content{
    width: 100%;
    &:last-child{
        display: none;
    }
}

.ac_menu-2_sticky {
    background: $ac-menu-2-main-background;
    // &.is-stuck {
    //     .secondary-menu {
    //         display: block !important;
    //     }
    // }
}

.ac_menu-2_overlay_menu {
    font-size: 120%; 
    color: $brand-darkest-gray;

}
.ac_menu-2_overlay_menu_secondary {
    color: $brand-darkest-gray;

}

.ac_menu-2_mobile_btn {
    @media screen and (max-width: 370px){
        padding: 12.5px 2px;
        width: 34px;

        &[data-toggle-menu-search] {
            display: none;
        }
    }
}
